<template>
  <v-text-field
    class="ma-0 pa-0"
    :value="value"
    required
    :label="label"
    dense
    outlined
    readonly
  />
</template>

<script>
export default {
  name: "LabelText",
  props: ["label", "value"],
};
</script>

<style></style>
