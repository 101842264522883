<template>
  <v-card class="mb-2">
    <div class="pa-4">
      <slot />
    </div>
  </v-card>
</template>

<script>
export default {
  name: "PlainCard",
};
</script>

<style></style>
