<template>
  <div>
    <v-container>
      <PlainCard>
        <v-btn color="primary" @click="dialog = true">Export</v-btn>
      </PlainCard>
      <PlainCard>
        <div class="invoice-details" style="display: grid; grid-template-columns: repeat(4, 1fr); grid-column-gap: 20px">
          <v-text-field readonly label="Air/Ship No" :value="shipmentDetail.via" />
          <v-text-field readonly label="Air/Ship No" :value="shipmentDetail.airShipNo" />
          <v-text-field readonly label="Invoice No" :value="shipmentDetail.invoiceNo" />
          <v-text-field readonly label="CTNS" :value="shipmentDetail.cartons" />
          <v-text-field readonly label="C/O" :value="shipmentDetail.co" />
          <v-text-field readonly label="Shipping date" :value="formateDate(shipmentDetail.shippingDate, false)" />
          <v-text-field readonly label="Expected Delivery date" :value="formateDate(shipmentDetail.expectedDeliveryDate, false)" />
          <v-text-field label="Total Amount" readonly :value="shipmentDetail.total" />
        </div>
      </PlainCard>
    </v-container>
    <v-container>
      <PlainCard v-for="item in shipmentDetail.shippingChild" :key="item.id" class="mb-5">
        <div class="invoice-details" style="display: grid; grid-template-columns: repeat(5, 1fr); grid-column-gap: 3px">
          <v-img class="img" contain height="150" :src="getImage(item.product.imageLink)" @click="previewURL(item.product.imageLink)" />
          <LabelText label="Product Name" :value="item.product.name" />
          <LabelText label="Product Code" :value="item.product.code" />
          <LabelText label="Branch Code" :value="item.branch.code" />
          <LabelText label="Order ID" :value="item.orderChild.order.id" />
          <LabelText label="Price" :value="item.product.price" />
          <LabelText label="Total Amount" :value="item.total" />
          <LabelText label="Order Quantity" :value="item.orderChild.quantity" />
          <LabelText label="Shipped Total Price" :value="item.total" />
          <LabelText label="Shipped Quantity" :value="item.shippedQuantity" />
          <LabelText label="Remaining Quantity" value="dad" />
          <LabelText label="Excess Quantity" :value="item.excessQuantity" />
        </div>
        <v-divider></v-divider>
        <div class="shipping-item-list row mt-0">
          <div class="col-md 12 mt-0">
            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">CTN NO</th>
                    <th class="text-left">PCS/CTN</th>
                    <th class="text-left">CTNS</th>
                    <th class="text-left">QTY</th>
                    <th class="text-left">UNIT PRICE</th>
                    <th class="text-left">TOTAL PRICE</th>
                  </tr>
                </thead>
                <tbody>
                  <tr class="item" v-for="(subItem, index) in item.shippingCarton" :key="index">
                    <td>
                      <input type="text" v-model="subItem.ctnNo" required />
                    </td>
                    <td>
                      <input type="text" v-model="subItem.pcsCtn" required />
                    </td>
                    <td>
                      <input type="text" v-model="subItem.ctns" required />
                    </td>
                    <td>
                      <input readonly type="text" v-model="subItem.quantity" />
                    </td>
                    <td>
                      <input readonly type="text" v-model="item.product.price" />
                    </td>
                    <td>
                      <input readonly type="text" v-model="subItem.total" />
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </div>
        </div>
      </PlainCard>
    </v-container>
    <!-- Dialogue box for Supplier codes -->
    <v-row justify="center">
      <v-dialog v-model="dialog" max-width="290" scrollable>
        <v-card>
          <v-card-title>Select Suppliers</v-card-title>
          <v-divider></v-divider>

          <v-card-text style="height: 600px">
            <!-- TODO add select all later -->
            <v-checkbox label="Select all" @change="selectAllSuppliers" />
            <v-checkbox v-for="item in suppliers" :key="item" :value="item" :label="item" v-model="selectedSuppliers" />
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="green darken-1" text @click="dialog = false"> Cancel </v-btn>

            <v-btn color="green darken-1" text @click="downloadExcelSheet()"> Export </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
    <!-- End -->
  </div>
</template>

<script>
import { formatDate, getImage } from "../../../common/utils";
import PlainCard from "../../../components/PlainCard.vue";
import { downloadExportShippingDetails, getShipmentDetail } from "./lib";
import LabelText from "../Pending/components/LabelText.vue";
export default {
  name: "HistoryDetails",
  watch: {},
  computed: {},
  data() {
    return {
      shipmentDetail: {},
      dialog: false,
      suppliers: [],
      selectedSuppliers: [],
    };
  },
  methods: {
    formateDate: formatDate,
    getImage: getImage,
    async fetchData() {
      this.$store.dispatch("setLoading", true);
      this.shipmentDetail = await getShipmentDetail(this.$route.params.id);
      this.$store.dispatch("setLoading", false);

      this.shipmentDetail.shippingChild.map((x) => {
        if (!this.suppliers.includes(x.product.supplierCode)) {
          this.suppliers.push(x.product.supplierCode);
        }
      });
    },
    totalProductsPrice() {
      var v = this.shipmentDetail?.map((i) => {
        return i.total;
      });
      return v.reduce((a, b) => a + b, 0);
    },
    async downloadExcelSheet() {
      let body = {
        shipping_id: this.$route.params.id,
        suppliers: this.selectedSuppliers,
      };

      await this.$store.dispatch("setLoading", true);
      await downloadExportShippingDetails(body);
      await this.$store.dispatch("setLoading", false);
      this.selectedSuppliers = [];
      this.dialog = false;
    },
    selectAllSuppliers(val) {
      if (val) {
        this.selectedSuppliers = this.suppliers;
      } else {
        this.selectedSuppliers = [];
      }
    },
    previewURL(imageLink) {
      this.$viewerApi({
        options: {
          inline: false,
          button: true,
          navbar: false,
          title: false,
          toolbar: false,
          tooltip: false,
          movable: true,
          zoomable: true,
          rotatable: true,
          scalable: true,
          transition: true,
          fullscreen: false,
          keyboard: false,
        },
        images: [getImage(imageLink)],
      });
    },
  },
  created() {
    // watch the params of the route to fetch the data again
    this.$watch(
      () => this.$route.params,
      () => {
        this.fetchData();
      },
      // fetch the data when the view is created and the data is
      // already being observed
      { immediate: true }
    );
  },
  components: { PlainCard, LabelText },
};
</script>

<style scoped>
.item input {
  width: 6rem;
  padding-left: 0.3rem;
  border-width: 1px;
  border-radius: 5px;
}
.invoice-details .v-text-field--outlined >>> fieldset {
  border-style: none !important;
  border-color: rgba(255, 255, 255, 0.986) !important;
}

.img {
  grid-area: 1 / 1 / 4 / 2;
}
</style>
